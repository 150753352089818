import { render, staticRenderFns } from "./ListDBL.vue?vue&type=template&id=71d0bab8&scoped=true&"
import script from "./ListDBL.vue?vue&type=script&lang=js&"
export * from "./ListDBL.vue?vue&type=script&lang=js&"
import style0 from "./ListDBL.vue?vue&type=style&index=0&id=71d0bab8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d0bab8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\40420\\OneDrive\\桌面\\星學會\\DaMou-Frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71d0bab8')) {
      api.createRecord('71d0bab8', component.options)
    } else {
      api.reload('71d0bab8', component.options)
    }
    module.hot.accept("./ListDBL.vue?vue&type=template&id=71d0bab8&scoped=true&", function () {
      api.rerender('71d0bab8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/雙人合盤/ListDBL.vue"
export default component.exports