var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "checkInfo" } },
    [
      _c("Stepper", { attrs: { step: 1 } }),
      _vm.productDetail
        ? _c("div", { staticClass: "card orderInfo" }, [
            _c("div", { staticClass: "card_title" }, [_vm._v("訂單內容")]),
            _vm.receiverMemberId
              ? _c("div", { staticClass: "orderInfo_commodity" }, [
                  _c("span", [_vm._v("贈")]),
                  _vm._v(" " + _vm._s(_vm.productDetail.Name) + " "),
                  _c("span", [_vm._v("給")]),
                  _c("br", { staticClass: "hidden-mobile-up" }),
                  _vm._v("朋友\n      "),
                  _c("span", [
                    _vm._v("(ID:" + _vm._s(_vm.receiverMemberId) + ")")
                  ])
                ])
              : _c("div", { staticClass: "orderInfo_commodity" }, [
                  _vm._v("\n      " + _vm._s(_vm.productDetail.Name) + "\n    ")
                ]),
            _c("div", { staticClass: "orderInfo_canUse" }, [
              _vm._v("\n      適用\n      "),
              _c("span", [_vm._v(_vm._s(_vm.productDetail.Appliance))])
            ]),
            _c("div", { staticClass: "orderInfo_price" }, [
              _c("div", { staticClass: "price" }, [
                _c("span", [_vm._v("NT.")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.productDetail.Amount * _vm.butQuantity))
                ])
              ]),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "orderInfo_detail" }, [
              _c(
                "div",
                {
                  staticClass: "orderInfo_detail_title",
                  on: {
                    click: function($event) {
                      _vm.openDetail = !_vm.openDetail
                    }
                  }
                },
                [
                  _c("div", [
                    _c("span"),
                    _c("span", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.openDetail,
                          expression: "!openDetail"
                        }
                      ]
                    })
                  ]),
                  _c("h3", [_vm._v("注意事項")])
                ]
              ),
              _c("div", {
                staticClass: "orderInfo_detail_content",
                class: { open: _vm.openDetail },
                domProps: { innerHTML: _vm._s(_vm.productDetail.Notice) }
              })
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "card userInfo" }, [
        _c("div", { staticClass: "card_title mb-7" }, [_vm._v("基本資料")]),
        _c("label", { staticClass: "inputItem", attrs: { for: "name" } }, [
          _c("span", [_vm._v("姓名")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.name,
                expression: "name",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              id: "name",
              placeholder: "請輸入與信用卡相同的姓名"
            },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ]),
        _c("label", { staticClass: "inputItem", attrs: { for: "tel" } }, [
          _c("span", [_vm._v("電話")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.phone,
                expression: "phone",
                modifiers: { trim: true }
              }
            ],
            attrs: { type: "tel", id: "tel", placeholder: "請輸入電話" },
            domProps: { value: _vm.phone },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ]),
        _c("label", { staticClass: "inputItem", attrs: { for: "email" } }, [
          _c("span", [_vm._v("信箱")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.email,
                expression: "email",
                modifiers: { trim: true }
              }
            ],
            attrs: { type: "email", id: "email", placeholder: "請輸入信箱" },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ]),
        _c(
          "div",
          {
            staticClass: "saveInfo",
            on: {
              click: function($event) {
                _vm.saveInfo = !_vm.saveInfo
              }
            }
          },
          [
            _c("span", [
              _c(
                "svg",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.saveInfo,
                      expression: "saveInfo"
                    }
                  ],
                  attrs: {
                    width: "12",
                    height: "10",
                    viewBox: "0 0 12 10",
                    fill: "#f00",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M11.8992 1.39981L5.03808 9.73314L4.10162 9.76547L0.129395 5.56794L1.03731 4.70876L4.52299 8.39216L10.9342 0.605286L11.8992 1.39981Z",
                      fill: "#9B9B9B"
                    }
                  })
                ]
              )
            ]),
            _vm._v("\n      記住本次基本資訊\n    ")
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "nextBtn",
          class: { notReady: !_vm.verify },
          on: {
            click: function($event) {
              return _vm.toNext()
            }
          }
        },
        [_vm._v("\n    下一步\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payMethods" }, [
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/cc-visa.svg"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/master.svg"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/Vector.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }